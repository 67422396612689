import {isTest, serviceUrl} from './config'

export const url = path => serviceUrl + path

export const fetcher = url => fetch(url).then(res => res.json())

export const post = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
  return response.json()
}

export function chainLabel(chainId) {
  const names = {
    eth: 'Ethereum',
    bnb: 'BNB Chain',
    arb: 'Arbitrum',
    op: 'Optimism',
  }

  return names[getChainId(chainId)]
}

export function isChainValid(chainId) {
  return isTest ? [3, 97, '0x3', '0x61'].includes(chainId) : [1, '0x1', 10, '0xa', 56, '0x38', 42161, '0xa4b1'].includes(chainId)
}

export function getChainId(chainId) {
  if(typeof chainId == 'string') {
    switch(chainId) {
      case '0x1':
        return 'eth';
      case '0xa':
        return 'op';
      case '0x38':
        return 'bnb';
      case '0xa4b1':
        return 'arb';
    }
  } else {
    switch(chainId) {
      case 1:
        return 'eth';
      case 10:
        return 'op';
      case 56:
        return 'bnb';
      case 42161:
        return 'arb';
    }
  }
}

//export const isChainBsc = chainId => (typeof chainId == 'string' ? ['0x38', '0x61', '0xa4b1'] : [56, 97, 42161]).includes(chainId)

let contracts
export async function getTokenAddress(chainId) {
  //if (!contracts) {
  //  contracts = await (await fetch(url('/contracts'))).json()
  //}
  return "0x2ba64efb7a4ec8983e22a49c81fa216ac33f383a";
}
